import { useCallback, useContext, useMemo } from 'react'

import {
  Box,
  Button,
  Chip,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import { useGridApiContext, type GridSortItem } from '@mui/x-data-grid-premium'
import {
  IconArrowBigDown,
  IconArrowBigUp,
  IconDownload,
  IconX,
} from '@tabler/icons-react'

import { logger } from 'src/lib/logger'
import { modernButtonContainer, modernTabStyles } from 'src/lib/style'

import Row from '../Row/Row'
import SidebarButton from '../Sidebar/SidebarButton/SidebarButton'

import ColumnSelectorChip from './ColumnSelectorChip'
import {
  viewManagerContainerSx,
  tabsContainerSx,
  displaySettingsSx,
} from './styles'
import ViewCreate from './ViewCreate'
import ViewFilters from './ViewFilters'
import ViewsContext from './ViewsContext'
import type { ViewsContextValue } from './ViewsContext'
import ViewTab from './ViewTab'

interface ViewManagerProps {
  showDisplaySettings: boolean
  setShowDisplaySettings: (_) => void
}

const ViewManager: React.FC<ViewManagerProps> = ({
  showDisplaySettings,
  setShowDisplaySettings,
}) => {
  const viewsContext = useContext(ViewsContext) as ViewsContextValue
  const apiGridRef = useGridApiContext()

  const {
    views,
    pinnedViews,
    currentView,
    loading,
    saving: _saving,
    activateView,
  } = viewsContext

  const apiRef = useGridApiContext()

  const handleViewChange = useCallback(
    (viewId: string | null) => {
      if (!viewId) return

      const view = views.find((v) => v.id === viewId)
      if (view) {
        activateView(view)
      }
    },
    [views, activateView]
  )

  const handleToggleSort = (field, remove = false) => {
    const currentSortModel = apiGridRef.current.getSortModel()
    const newSortModel = currentSortModel
      .map((sort) => {
        if (sort.field === field) {
          const newSort = remove ? null : sort.sort === 'asc' ? 'desc' : 'asc'
          return { ...sort, sort: newSort }
        }
        return sort
      })
      .filter((sort) => sort.sort !== null) as GridSortItem[]
    logger.dev('newSortModel', newSortModel)
    apiGridRef.current.setSortModel(newSortModel)
  }

  const sortsCount = apiGridRef.current.exportState().sorting.sortModel.length
  const filtersCount =
    apiGridRef.current.exportState().filter.filterModel.items.length
  const groupingsCount =
    apiGridRef.current.exportState().rowGrouping.model.length

  const modifiersState = useMemo(() => {
    const total = sortsCount + filtersCount + groupingsCount + 1
    return {
      total,
      sorts: sortsCount,
      filters: filtersCount,
      groupings: groupingsCount,
    }
  }, [sortsCount, filtersCount, groupingsCount])

  return (
    <Box>
      <Row sx={viewManagerContainerSx}>
        <Row className="view-manager-tabs-container">
          <Row sx={{ ...tabsContainerSx, ...modernTabStyles }}>
            {pinnedViews.length > 0 ? (
              <Tabs
                value={0}
                visibleScrollbar={true}
              >
                {pinnedViews.map((view) => (
                  <ViewTab
                    key={view.id}
                    view={view}
                    currentView={currentView}
                    setCurrentView={handleViewChange}
                  />
                ))}
              </Tabs>
            ) : (
              <Tabs value={loading ? 'loading' : 'all'}>
                <Tab
                  value={loading ? 'loading' : 'all'}
                  disabled={loading}
                  label={
                    <Row>
                      <Typography>{loading ? 'Loading...' : 'All'}</Typography>
                    </Row>
                  }
                />
              </Tabs>
            )}
            <Row>
              <ViewCreate />
            </Row>
          </Row>

          <Row gap={1}>
            <Tooltip
              title="Export"
              arrow={true}
            >
              <IconButton className="export-button">
                <IconDownload
                  size={12}
                  stroke={2.5}
                />
              </IconButton>
            </Tooltip>

            {!showDisplaySettings && (
              <Tooltip
                title={
                  showDisplaySettings
                    ? 'Hide display settings'
                    : 'Show display settings'
                }
                arrow={true}
              >
                <Box sx={{ ...modernButtonContainer }}>
                  <Button
                    onClick={() => setShowDisplaySettings((prev) => !prev)}
                    size="small"
                    variant="outlined"
                    className="display-settings-button right flat"
                    startIcon={
                      <Row className="modifier-badge-row">
                        {modifiersState.sorts > 0 && (
                          <Box className="modifier-badge sorts" />
                        )}
                        {modifiersState.groupings > 0 && (
                          <Box className="modifier-badge groupings" />
                        )}
                        {modifiersState.filters > 0 && (
                          <Box className="modifier-badge filters" />
                        )}
                        <Box className="modifier-badge columns" />
                        <Box className="modifiers-count">
                          <Typography>{modifiersState.total}</Typography>
                        </Box>
                      </Row>
                    }
                  >
                    {'Settings'}
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Row>
        </Row>
      </Row>
      {showDisplaySettings && (
        <Row sx={displaySettingsSx}>
          <Row className="chip-row">
            <ColumnSelectorChip />
            {(apiRef?.current.getSortModel() || []).map((sort) => (
              <Chip
                key={sort.field}
                size="small"
                variant="outlined"
                className="sorts"
                onDelete={() => handleToggleSort(sort.field, true)}
                deleteIcon={
                  <IconX
                    size={12}
                    stroke={2.5}
                  />
                }
                onClick={() => handleToggleSort(sort.field)}
                label={
                  apiRef?.current
                    .getAllColumns()
                    .find((c) => c.field === sort.field)?.headerName ||
                  sort.field
                }
                icon={
                  sort.sort === 'asc' ? (
                    <IconArrowBigUp
                      size={6}
                      stroke={2.5}
                    />
                  ) : (
                    <IconArrowBigDown
                      size={6}
                      stroke={2.5}
                    />
                  )
                }
              />
            ))}

            <ViewFilters />
          </Row>

          <Tooltip
            title="Hide Display Settings"
            arrow={true}
            placement="left"
          >
            <SidebarButton
              onClick={() => {
                setShowDisplaySettings(false)
              }}
              flat={true}
              label="Hide"
              icon={<IconX size={16} />}
            />
          </Tooltip>
        </Row>
      )}
    </Box>
  )
}

export default ViewManager
