import type { ObjectPropertyDefinition } from 'types/graphql'

import { enumOptionsAsPropDefOptions } from 'src/components/Objects/ObjectsTable/cells'

import { logger } from '../logger'
import {
  PropertyTypes,
  NativeObjectTypes,
  OrganizationObjectRootProperties,
  NativePipelineTypes,
  StageTypes,
  OpportunityRoleTypes,
} from '../objects'
import type { NativeObjectType, PropertyTypeKey } from '../objects'

import {
  ensureArrayOfObjectsFromJsonString,
  ensureArrayOfStringsFromJsonString,
  ensureDateTime,
  ensureFloatFromString,
  ensureIntegerFromString,
  ensureLinkedInProfileUrl,
  ensureObjectFromJsonString,
  ensureUrl,
  ensureValidObjectReference,
  ensureXUrl,
  renderCertifications,
} from './formatters'
import { ensureArrayOfIntegersFromJsonString } from './formatters'
import { ensureJsonOrNullFromArray } from './formatters'

export enum StandardPropertyTablePolicy {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
  DEFAULT = 'DEFAULT',
}

export enum StandardPropertyEditPolicy {
  ALLOW = 'ALLOW',
  CONTEXT_ONLY = 'CONTEXT_ONLY',
  DISABLED = 'DISABLED',
}

interface StandardPropertyDefinition {
  name: string
  propertyTypeId: PropertyTypeKey
  caster: (value: any) => any
  renderer: (value: any) => any
  path?: OrganizationObjectRootProperties | null
  fieldName?: string | null
  description?: string | null
  tablePolicy?: StandardPropertyTablePolicy
  editPolicy?: StandardPropertyEditPolicy
}

export const StandardPropertyAsObjectPropertyDefinition = ({
  objectType,
  propertyId,
  workspaceId,
}: {
  objectType: NativeObjectType
  propertyId: string
  workspaceId: string
}): ObjectPropertyDefinition => {
  const standardPropDef = StandardProperties[objectType]?.[propertyId]
  if (!standardPropDef) {
    logger.warn('Standard property definition not found', {
      objectType,
      propertyId,
    })
    return null
  }

  if (!standardPropDef) {
    logger.warn('Standard property definition not found', {
      objectType,
      propertyId,
    })
  }

  return {
    id: propertyId,
    objectTypeId: objectType,
    propertyTypeId: standardPropDef.propertyTypeId,
    propertyType: {
      id: standardPropDef.propertyTypeId,
      name: standardPropDef.name,
      description: standardPropDef.description,
    },
    name: standardPropDef.name,
    description: standardPropDef.description,
    aiManaged: false,
    options: enumOptionsAsPropDefOptions(objectType, propertyId),
    useWeb: false,
    createdAt: new Date(0).toISOString(),
    updatedAt: new Date(0).toISOString(),
    workspaceId,
  }
}

export const StandardProperties: Record<
  string,
  Record<string, StandardPropertyDefinition>
> = {
  [NativeObjectTypes.Organization]: {
    name: {
      name: 'Name',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Root,
      description: 'The name of the organization',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    description: {
      name: 'Description',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.About,
      fieldName: 'description',
      description: 'The description of the organization',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    aiDescription: {
      name: 'Description',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.About,
      fieldName: 'aiDescription',
      description: 'The AI-generated description of the organization',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    founded: {
      name: 'Founded',
      propertyTypeId: PropertyTypes.Integer as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : value,
      renderer: (value: string) => parseInt(value),
      path: OrganizationObjectRootProperties.About,
      fieldName: 'founded',
      description: 'The year the organization was founded',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    promises: {
      name: 'Marketing Promises',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: ensureJsonOrNullFromArray,
      renderer: ensureArrayOfStringsFromJsonString,
      path: OrganizationObjectRootProperties.Purpose,
      fieldName: 'marketingPromises',
      description: 'The marketing promises of the organization',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    naicsCodes: {
      name: 'NAICS Codes',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: ensureJsonOrNullFromArray,
      renderer: ensureArrayOfIntegersFromJsonString,
      path: OrganizationObjectRootProperties.Codes,
      fieldName: 'naics',
      description: 'The NAICS codes of the organization',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    sicCodes: {
      name: 'SIC Codes',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: ensureJsonOrNullFromArray,
      renderer: ensureArrayOfStringsFromJsonString,
      path: OrganizationObjectRootProperties.Codes,
      fieldName: 'sic',
      description: 'The SIC codes of the organization',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    industry: {
      name: 'Industry',
      propertyTypeId: PropertyTypes.Picklist as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Root,
      description: 'The industry of the organization',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    specialities: {
      name: 'Specialities',
      propertyTypeId: PropertyTypes.MultiPicklist as PropertyTypeKey,
      caster: (value: string) =>
        value && Array.isArray(value) ? JSON.stringify(value) : null,
      renderer: ensureArrayOfStringsFromJsonString,
      path: OrganizationObjectRootProperties.About,
      fieldName: 'specialities',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    followerCount: {
      name: 'Follower Count',
      propertyTypeId: PropertyTypes.Integer as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : value,
      renderer: (value: string) => ensureIntegerFromString(value),
      path: OrganizationObjectRootProperties.About,
      fieldName: 'followers',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    phoneNumbers: {
      name: 'Phone Numbers',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: ensureJsonOrNullFromArray,
      renderer: ensureArrayOfObjectsFromJsonString,
      path: OrganizationObjectRootProperties.About,
      fieldName: 'phoneNumbers',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    employeeCountFrom: {
      name: 'Employee Count (from)',
      propertyTypeId: PropertyTypes.Integer as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : value,
      renderer: (value: string) => ensureIntegerFromString(value),
      path: OrganizationObjectRootProperties.About,
      fieldName: 'employeesFrom',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    employeeCountTo: {
      name: 'Employee Count (to)',
      propertyTypeId: PropertyTypes.Integer as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : value,
      renderer: (value: string) => ensureIntegerFromString(value),
      path: OrganizationObjectRootProperties.About,
      fieldName: 'employeesTo',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    employeeCount: {
      name: 'Employee Count',
      propertyTypeId: PropertyTypes.Integer as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : value,
      renderer: (value: string) => ensureIntegerFromString(value),
      path: OrganizationObjectRootProperties.Root,
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    doesBusinessWith: {
      name: 'Sells to',
      propertyTypeId: PropertyTypes.MultiPicklist as PropertyTypeKey,
      caster: (value: string) => (value ? JSON.stringify(value) : null),
      renderer: (value: string) =>
        ensureArrayOfStringsFromJsonString(value).filter((s) =>
          ['B2B', 'B2C', 'B2G'].includes(s)
        ),
      path: OrganizationObjectRootProperties.About,
      fieldName: 'selling',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,

      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    idealCustomerProfile: {
      name: 'Ideal Customer Profile',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => (value ? JSON.stringify(value) : null),
      renderer: ensureObjectFromJsonString,
      path: OrganizationObjectRootProperties.About,
      fieldName: 'icp',
      tablePolicy: StandardPropertyTablePolicy.HIDE,

      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    missionAndVision: {
      name: 'Mission & Vision',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Purpose,
      fieldName: 'missionAndVision',
      tablePolicy: StandardPropertyTablePolicy.SHOW,

      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    values: {
      name: 'Organizational Values',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => (value ? JSON.stringify(value) : null),
      renderer: ensureArrayOfStringsFromJsonString,
      path: OrganizationObjectRootProperties.Purpose,
      fieldName: 'values',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    differentiators: {
      name: 'Differentiators',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => (value ? JSON.stringify(value) : null),
      renderer: ensureArrayOfStringsFromJsonString,
      path: OrganizationObjectRootProperties.Purpose,
      fieldName: 'differentiators',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    isHiring: {
      name: 'Is Hiring',
      propertyTypeId: PropertyTypes.Boolean as PropertyTypeKey,
      caster: (value: boolean) =>
        typeof value === 'boolean' ? value.toString() : value,
      renderer: (value: string) => value === 'true',
      path: OrganizationObjectRootProperties.About,
      fieldName: 'isHiring',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    industryType: {
      name: 'Industry Type',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.About,
      fieldName: 'industry',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    annualRevenue: {
      name: 'Annual Revenue',
      propertyTypeId: PropertyTypes.Float as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : value,
      renderer: (value: string) => ensureFloatFromString(value),
      path: OrganizationObjectRootProperties.About,
      fieldName: 'revenue',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    funding: {
      name: 'Funding Raised',
      propertyTypeId: PropertyTypes.Float as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : value,
      renderer: (value: string) => ensureFloatFromString(value),
      path: OrganizationObjectRootProperties.About,
      fieldName: 'funding',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    location: {
      name: 'Location',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Headquarters,
      fieldName: 'location',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    address: {
      name: 'Address',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Headquarters,
      fieldName: 'address',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    city: {
      name: 'City',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Headquarters,
      fieldName: 'city',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    state: {
      name: 'State',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Headquarters,
      fieldName: 'state',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    country: {
      name: 'Country',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Headquarters,
      fieldName: 'country',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    postalCode: {
      name: 'Postal Code',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Headquarters,
      fieldName: 'postalCode',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    latitude: {
      name: 'Latitude',
      propertyTypeId: PropertyTypes.Float as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : value,
      renderer: (value: string) => ensureFloatFromString(value),
      path: OrganizationObjectRootProperties.Headquarters,
      fieldName: 'latitude',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    longitude: {
      name: 'Longitude',
      propertyTypeId: PropertyTypes.Float as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : value,
      renderer: (value: string) => ensureFloatFromString(value),
      path: OrganizationObjectRootProperties.Headquarters,
      fieldName: 'longitude',
      tablePolicy: StandardPropertyTablePolicy.HIDE,

      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    colorVibrant: {
      name: 'Color',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Colors,
      fieldName: 'colorVibrant',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    colorDarkVibrant: {
      name: 'Color',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Colors,
      fieldName: 'colorDarkVibrant',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    colorLightVibrant: {
      name: 'Color',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Colors,
      fieldName: 'colorLightVibrant',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    colorMuted: {
      name: 'Color',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Colors,
      fieldName: 'colorMuted',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    colorDarkMuted: {
      name: 'Color',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Colors,
      fieldName: 'colorDarkMuted',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    colorLightMuted: {
      name: 'Color',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Colors,
      fieldName: 'colorLightMuted',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    photoSquare: {
      name: 'Photo (avatar)',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: ensureUrl,
      path: OrganizationObjectRootProperties.Photos,
      fieldName: 'square',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    photosOther: {
      name: 'Photos (other)',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfObjectsFromJsonString,

      path: OrganizationObjectRootProperties.Photos,
      fieldName: 'other',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    photoIcon: {
      name: 'Photo (icon)',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: ensureUrl,
      path: OrganizationObjectRootProperties.Photos,
      fieldName: 'icon',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    photoBanner: {
      name: 'Photo (banner)',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: ensureUrl,
      path: OrganizationObjectRootProperties.Photos,
      fieldName: 'banner',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    stockTicker: {
      name: 'Stock Ticker',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Codes,
      fieldName: 'ticker',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    socialTwitter: {
      name: 'Twitter',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: ensureXUrl,
      path: OrganizationObjectRootProperties.Links,
      fieldName: 'x',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    socialLinkedIn: {
      name: 'LinkedIn',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: ensureUrl,
      path: OrganizationObjectRootProperties.Links,
      fieldName: 'linkedIn',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    socialFacebook: {
      name: 'Facebook',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: ensureUrl,
      path: OrganizationObjectRootProperties.Links,
      fieldName: 'facebook',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    socialYouTube: {
      name: 'YouTube',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: ensureUrl,
      path: OrganizationObjectRootProperties.Links,
      fieldName: 'youtube',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    socialInstagram: {
      name: 'Instagram',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: ensureUrl,
      path: OrganizationObjectRootProperties.Links,
      fieldName: 'instagram',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    edgarCik: {
      name: 'Edgar CIK',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Codes,
      fieldName: 'edgar',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    crunchbaseEntityId: {
      name: 'Crunchbase ID',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Codes,
      fieldName: 'crunchbase',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    linkCrunchbase: {
      name: 'Crunchbase',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: ensureUrl,
      path: OrganizationObjectRootProperties.Links,
      fieldName: 'crunchbase',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    linkAngelList: {
      name: 'AngelList',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: ensureUrl,
      path: OrganizationObjectRootProperties.Links,
      fieldName: 'angellist',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    keywords: {
      name: 'Keywords',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: any[]) => (value ? JSON.stringify(value) : null),
      renderer: ensureArrayOfStringsFromJsonString,
      path: OrganizationObjectRootProperties.About,
      fieldName: 'keywords',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    traffic: {
      name: 'Traffic',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: any) => (value ? JSON.stringify(value) : null),
      renderer: ensureObjectFromJsonString,
      path: OrganizationObjectRootProperties.About,
      fieldName: 'traffic',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    similarDomains: {
      name: 'Similar Domains',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: any[]) => (value ? JSON.stringify(value) : null),
      renderer: ensureArrayOfStringsFromJsonString,
      path: OrganizationObjectRootProperties.Root,
      fieldName: 'similarDomains',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    resolvedUrl: {
      name: 'Resolved URL',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Links,
      fieldName: 'websiteResolvedUrl',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    opportunityIds: {
      name: 'Opportunities',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string[]) => JSON.stringify(value.filter(Boolean)),
      renderer: ensureArrayOfStringsFromJsonString,
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'opportunityIds',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    resolvedStatusCode: {
      name: 'Resolved Status Code',
      propertyTypeId: PropertyTypes.Integer as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : value,
      renderer: ensureIntegerFromString,
      path: OrganizationObjectRootProperties.Links,
      fieldName: 'websiteResolvedStatusCode',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    quotes: {
      name: 'Quotes',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfObjectsFromJsonString,
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'quotes',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    upcomingEvents: {
      name: 'Upcoming Events',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfObjectsFromJsonString,
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'upcomingEvents',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    news: {
      name: 'News',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfObjectsFromJsonString,
      path: OrganizationObjectRootProperties.About,
      fieldName: 'news',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    ['status/highLevelSummary']: {
      name: 'Status (detailed)',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfStringsFromJsonString,
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'highLevelSummary',
      description:
        'The Organization Status field provides a concise snapshot of your relationship with each organization by intelligently synthesizing your most recent interactions. Drawing from email exchanges, meeting transcripts, team notes, Slack conversations, and scheduled events, our system prioritizes recency and relevance to surface the most important developments. We focus on delivering specific, actionable insights about your current relationship stage, significant developments, pending decisions, and key opportunities—all distilled into a brief, business-focused summary that updates automatically as new interactions are captured. This ensures you always have immediate context when engaging with any organization in your workspace.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    ['status/warmth']: {
      name: 'Status (warmth)',
      propertyTypeId: PropertyTypes.Integer as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : value,
      renderer: ensureIntegerFromString,
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'warmth',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    ['status/currentStatusOneSentence']: {
      name: 'Status',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'oneSentenceSummary',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    ['status/sensitiveforWho']: {
      name: 'Sensitive to Whom',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfStringsFromJsonString,
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'sensitiveToWhom',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    ['sensitive/sensitiveReasoning']: {
      name: 'Sensitive Reasoning',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfObjectsFromJsonString,
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'sensitiveReasoning',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    ['status/genericPipeline']: {
      name: 'Generic Pipeline',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureObjectFromJsonString,
      path: OrganizationObjectRootProperties.Lifecycle,
      fieldName: null,
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    ['objective/proofOfPaymentBoolean']: {
      name: 'Proof of Payment',
      propertyTypeId: PropertyTypes.Boolean as PropertyTypeKey,
      caster: (value: boolean) => value.toString(),
      renderer: (value: string) => value === 'true',
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'proofOfPayment',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    ['objective/proofOfPayment']: {
      name: 'Proof of Payment Reasoning',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'proofOfPaymentReasoning',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    ['objective/relationshipOrigin']: {
      name: 'Relationship Origin',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => (value ? JSON.parse(value) : null),
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'origin',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    ['objective/roles']: {
      name: 'Roles',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: (value: string) => {
        const raw = ensureArrayOfObjectsFromJsonString(value)
        return (
          raw
            .map((r) => ({
              ...r,
              reasoning: (r as any)?.roleReasoning || '',
            }))
            .filter((r) => (r as any)?.email) || []
        )
      },
      path: OrganizationObjectRootProperties.Root,
      fieldName: 'roles',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    ['status/nextSteps']: {
      name: 'Next Steps',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: (value: string) => {
        const raw = ensureObjectFromJsonString(value)
        return (raw && (raw as any)?.immediateNextSteps) || []
      },
      path: OrganizationObjectRootProperties.Legacy,
      fieldName: 'nextSteps',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    ['status/recommendedStage']: {
      name: 'Recommended Stage',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: (_) => {
        return null
      },
      path: OrganizationObjectRootProperties.Legacy,
      fieldName: 'lifecycle',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    relationshipEdges: {
      name: 'Relationship Edges',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfStringsFromJsonString,
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'edges',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    'objective/meetingAndEmailTimeline': {
      name: 'Meeting and Email Timeline',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureObjectFromJsonString,
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'meetingAndEmailTimeline',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    'objective/slackSummary': {
      name: 'Slack Summary',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureObjectFromJsonString,
      path: OrganizationObjectRootProperties.Relationship,
      fieldName: 'slackSummary',
      tablePolicy: StandardPropertyTablePolicy.HIDE,

      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
  },
  [NativeObjectTypes.Person]: {
    email: {
      name: 'Email',
      propertyTypeId: PropertyTypes.Email as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The email address of the person',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    firstName: {
      name: 'First Name',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The first name of the person',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    lastName: {
      name: 'Last Name',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The last name of the person',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    linkedInUrl: {
      name: 'LinkedIn URL',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: ensureLinkedInProfileUrl,
      renderer: (value: string) => value,
      description: 'The LinkedIn profile URL of the person',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    description: {
      name: 'Description',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        'The career-focused, usually one-line summary/bio of the person. Often this is the person\'s LinkedIn "headline".',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    careerSummary: {
      name: 'Career Summary',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        "Paragraph-form history of the person's career, highlighting key experiences and the skills & abilities they are known for as a result of these experiences.",
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    canonicalEmail: {
      name: 'Canonical Email',
      propertyTypeId: PropertyTypes.Email as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        'The canonical email address of the person (if the Person in reality has multiple email addresses).',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    currentWorkEmail: {
      name: 'Current Work Email',
      propertyTypeId: PropertyTypes.Email as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        'The current work (corporate, non-freemail) email address of the person.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    pastEmails: {
      name: 'Past Emails',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfStringsFromJsonString,
      description:
        'All email addresses that have been associated with the person, including past work emails.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    phoneNumbers: {
      name: 'Phone Numbers',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfObjectsFromJsonString,
      description:
        'All phone numbers that have been associated with the person, including work phone numbers.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    location: {
      name: 'Location',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        'The physical location of the person, this could be general region, or a city, state, and country.',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    timezone: {
      name: 'Timezone',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        'The UTC timezone of the person, this could be general region, or a city, state, and country.',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    country: {
      name: 'Country',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        "The country of the person's primary residence and professional activities.",
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    city: {
      name: 'City',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        "The city of the person's primary residence and professional activities.",
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    state: {
      name: 'State',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        "The state of the person's primary residence and professional activities.",
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    postalCode: {
      name: 'Postal Code',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        "The postal code of the person's primary residence and professional activities.",
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    latitude: {
      name: 'Latitude',
      propertyTypeId: PropertyTypes.Float as PropertyTypeKey,
      caster: (value: string) => value.toString(),
      renderer: (value: string) => parseFloat(value),
      description:
        "The latitude of the person's primary residence and professional activities.",
      tablePolicy: StandardPropertyTablePolicy.HIDE,

      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    longitude: {
      name: 'Longitude',
      propertyTypeId: PropertyTypes.Float as PropertyTypeKey,
      caster: (value: string) => value.toString(),
      renderer: (value: string) => parseFloat(value),
      description:
        "The longitude of the person's primary residence and professional activities.",
      tablePolicy: StandardPropertyTablePolicy.HIDE,

      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    gender: {
      name: 'Gender',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The gender of the person.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    headline: {
      name: 'Headline',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        'The headline of the person, this is the person\'s LinkedIn "headline".',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    industry: {
      name: 'Industry',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The industry of the person.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    socialFacebook: {
      name: 'Facebook Profile',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The Facebook profile URL of the person.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    socialTwitter: {
      name: 'X (Twitter) Profile',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: ensureXUrl,
      description: 'The X (Twitter) profile URL of the person.',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    socialGithub: {
      name: 'GitHub Profile',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The GitHub profile URL of the person.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    socialLinkedIn: {
      name: 'LinkedIn Profile',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The LinkedIn profile URL of the person.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    photoUrl: {
      name: 'Profile Photo',
      propertyTypeId: PropertyTypes.Url as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        'The profile photo URL of the person. This image should be square, relatively high resolution. Often (and preferably) a social media avatar.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,

      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    currentCompanyName: {
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      name: 'Current Organization',
      description: "The name of the person's current organization.",
      tablePolicy: StandardPropertyTablePolicy.HIDE,

      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    currentJobTitle: {
      name: 'Current Job Title',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: "The job title of the person's current organization.",
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,

      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    currentJobStartDate: {
      name: 'Current Job Start Date',
      propertyTypeId: PropertyTypes.DateTime as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => new Date(value),
      description: "The start date of the person's current job.",
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    skills: {
      name: 'Skills',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfStringsFromJsonString,
      description: 'The skills of the person.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    languages: {
      name: 'Languages',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfStringsFromJsonString,
      description: 'The languages spoken by the person.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    interests: {
      name: 'Interests',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfStringsFromJsonString,
      description: 'The interests of the person.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    workExperience: {
      name: 'Work Experience',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfObjectsFromJsonString,
      description: 'The work experience of the person.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    education: {
      name: 'Education',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfObjectsFromJsonString,
      description: 'The education of the person.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    certifications: {
      name: 'Certifications',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: renderCertifications,
      description: 'The certifications of the person.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    verificationStatus: {
      name: 'Verification Status',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: (value: string) => JSON.parse(value),
      description: 'The verification status of the person.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      fieldName: 'verificationStatus',
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    relationshipEdges: {
      name: 'Relationship Edges',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => JSON.stringify(value),
      renderer: ensureArrayOfStringsFromJsonString,
      description:
        'The relationships of the person to the Day.ai Workspace in question.',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
  },
  [NativeObjectTypes.Pipeline]: {
    title: {
      name: 'Title',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The title of the pipeline',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,

      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    description: {
      name: 'Description',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The description of the pipeline',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    hasRevenue: {
      name: 'Has Revenue',
      propertyTypeId: PropertyTypes.Boolean as PropertyTypeKey,
      caster: (value: boolean) => value.toString(),
      renderer: (value: string) => value === 'true',
      description: 'Whether this pipeline tracks revenue',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    setupSteps: {
      name: 'Setup Steps',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: any) => (value ? JSON.stringify(value) : null),
      renderer: ensureObjectFromJsonString,
      description: 'Steps for setting up this pipeline',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    type: {
      name: 'Type',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        'The type of pipeline, must be one of: NEW_CUSTOMER, EXISTING_CUSTOMER, FINANCING_INVESTMENT, VENTURE_CAPITAL, PARTNER, RECRUITING, PERSONAL, NONE, or OTHER',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    automationActive: {
      name: 'Automation Active',
      propertyTypeId: PropertyTypes.Boolean as PropertyTypeKey,
      caster: (value: boolean) => value.toString(),
      renderer: (value: string) => value === 'true',
      description:
        'True if the user has set up Day.ai to actively manage this pipeline using automation, false if the user is manually managing it.',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    icpOrganization: {
      name: 'ICP Organization',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description:
        'Organization-level firmographic information & description. What makes an organization a fit for this pipeline? Size of company, industry, region/location, etc',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    icpMetadata: {
      name: 'ICP Metadata',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: any) => (value ? JSON.stringify(value) : null),
      renderer: ensureObjectFromJsonString,
      description:
        'Metadata for Ideal Customer Profile, including employee count, industry, country, region, etc',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    icpPeople: {
      name: 'ICP People',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: any) => (value ? JSON.stringify(value) : null),
      renderer: ensureObjectFromJsonString,
      description:
        'Role-level professional information & description for *target* prospects (not internal employees/users)',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
  },
  [NativeObjectTypes.Stage]: {
    title: {
      name: 'Title',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => (value !== 'null' ? value : null),
      renderer: (value: string) => value,
      description: 'The title of the stage',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    type: {
      name: 'Type',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) =>
        value && Object.keys(StageTypes).includes(value) ? value : null,
      description: 'The type of stage',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    description: {
      name: 'Description',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The description of the stage',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    likelihoodToClose: {
      name: 'Likelihood to Close',
      propertyTypeId: PropertyTypes.Float as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : null,
      renderer: (value: string) => (value ? parseFloat(value) : null),
      description: 'The likelihood of closing deals in this stage (0-1)',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    entranceCriteria: {
      name: 'Entrance Criteria',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: any) => (value ? JSON.stringify(value) : null),
      renderer: ensureArrayOfStringsFromJsonString,
      description: 'Criteria that must be met to enter this stage',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    pipelineId: {
      name: 'Pipeline ID',
      propertyTypeId: PropertyTypes.ObjectReference as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The ID of the pipeline this stage belongs to',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    position: {
      name: 'Position',
      propertyTypeId: PropertyTypes.Integer as PropertyTypeKey,
      caster: (value: number) => value.toString(),
      renderer: (value: string) => parseInt(value, 10),
      description: 'The position of this stage in the pipeline sequence',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
  },
  [NativeObjectTypes.Opportunity]: {
    title: {
      name: 'Title',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'title',
      description: 'The title of the opportunity',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    pipelineId: {
      name: 'Pipeline ID',
      propertyTypeId: PropertyTypes.ObjectReference as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'pipelineId',
      description: 'The ID of the pipeline this opportunity belongs to',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    stageId: {
      name: 'Stage ID',
      propertyTypeId: PropertyTypes.ObjectReference as PropertyTypeKey,
      caster: (value: string) => ensureValidObjectReference(value),
      renderer: (value: string) => ensureValidObjectReference(value),
      fieldName: 'stageId',
      description: 'The ID of the stage this opportunity is in',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    domain: {
      name: 'Domain',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'domain',
      description: 'The domain associated with the opportunity',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    type: {
      name: 'Type',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'type',
      description: 'The type of opportunity',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    roles: {
      name: 'Roles',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: any) => (value ? JSON.stringify(value) : null),
      renderer: (value: string) => {
        const roles = ensureArrayOfObjectsFromJsonString(value) || []
        const validRoles = roles.filter(
          (role: any) => role.personEmail && Array.isArray(role.roles)
        )

        if (!validRoles.length) {
          return []
        }

        return validRoles.map((role: any) => ({
          ...role,
          roles: role.roles.filter((r: string) =>
            Object.values(OpportunityRoleTypes).includes(r)
          ),
        }))
      },
      fieldName: 'roles',
      description: 'The roles associated with this opportunity',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    expectedCloseDate: {
      name: 'Expected Close Date',
      propertyTypeId: PropertyTypes.DateTime as PropertyTypeKey,
      caster: (value: Date) =>
        value
          ? typeof value === 'string'
            ? new Date(value).toISOString()
            : value.toISOString()
          : null,
      renderer: ensureDateTime,
      fieldName: 'expectedCloseDate',
      description: 'The expected close date for this opportunity',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    expectedRevenue: {
      name: 'Expected Revenue',
      propertyTypeId: PropertyTypes.Float as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : null,
      renderer: (value: string) => (value ? parseFloat(value) : null),
      fieldName: 'expectedRevenue',
      description: 'The expected revenue from this opportunity',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    isSuggested: {
      name: 'Is Suggested',
      propertyTypeId: PropertyTypes.Boolean as PropertyTypeKey,
      caster: (value: boolean) => value?.toString() ?? null,
      renderer: (value: string) => (value ? value === 'true' : null),
      fieldName: 'isSuggested',
      description: 'Whether this opportunity was suggested by the system',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    modelUpdatedAt: {
      name: 'Model Updated At',
      propertyTypeId: PropertyTypes.DateTime as PropertyTypeKey,
      caster: (value: Date) =>
        value ? ensureDateTime(value).toISOString() : null,
      renderer: (value: string) => ensureDateTime(value),
      fieldName: 'modelUpdatedAt',
      description: 'When the opportunity model was last updated',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    ownerEmail: {
      name: 'Owner Email',
      propertyTypeId: PropertyTypes.Email as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'ownerEmail',
      description: 'The email of the opportunity owner',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    position: {
      name: 'Position',
      propertyTypeId: PropertyTypes.Integer as PropertyTypeKey,
      caster: (value: number) =>
        typeof value === 'number' ? value.toString() : null,
      renderer: (value: string) => {
        return typeof value === 'string' ? parseInt(value, 10) : 0
      },
      fieldName: 'position',
      description: 'The position of the opportunity in the pipeline',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    pipelineTitle: {
      name: 'Pipeline Title',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'pipelineTitle',
      description: 'The title of the pipeline this opportunity belongs to',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    currentStatus: {
      name: 'Current Status',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'currentStatus',
      description: 'The current status of the opportunity',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    primaryPerson: {
      name: 'Primary Person',
      propertyTypeId: PropertyTypes.Email as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: () => null, // Deprecated
      fieldName: 'primaryPerson',
      description:
        'The primary person associated with this opportunity (deprecated)',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    recommendedStage: {
      name: 'Recommended Stage',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: any) => (value ? JSON.stringify(value) : null),
      renderer: (value: string) => {
        const obj = ensureObjectFromJsonString(value) || {}
        return {
          ...obj,
          expectedCloseDate: (obj as any).expectedCloseDate
            ? ensureDateTime((obj as any).expectedCloseDate)
            : null,
        }
      },
      fieldName: 'recommendedStage',
      description: 'The recommended stage for this opportunity',
      tablePolicy: StandardPropertyTablePolicy.HIDE,

      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    suggestedPipelineType: {
      name: 'Suggested Pipeline Type',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) =>
        value && Object.keys(NativePipelineTypes).includes(value)
          ? value
          : null,
      fieldName: 'suggestedPipelineType',
      description: 'The suggested pipeline type for this opportunity',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    suggestedStageType: {
      name: 'Suggested Stage Type',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) =>
        value && Object.keys(StageTypes).includes(value) ? value : null,
      fieldName: 'suggestedStageType',
      description: 'The suggested stage type for this opportunity',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    pipelineReasoning: {
      name: 'Pipeline Reasoning',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'pipelineReasoning',
      description: 'The reasoning for the pipeline suggestion',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    stageReasoning: {
      name: 'Stage Reasoning',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'stageReasoning',
      description: 'The reasoning for the stage suggestion',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    currentSituation: {
      name: 'Current Situation',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: any) =>
        value?.content ? JSON.stringify(value.content) : null,
      renderer: ensureArrayOfStringsFromJsonString,
      fieldName: 'status',
      description: 'The current situation of the opportunity',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    goalsAndKPIs: {
      name: 'Goals & KPIs',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: any) => (value ? JSON.stringify(value) : null),
      renderer: ensureObjectFromJsonString,
      fieldName: 'goals',
      description: 'The goals and KPIs for this opportunity',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
    challengesAndSolutions: {
      name: 'Challenges & Solutions',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: any) => {
        return value
          ? Array.isArray(value)
            ? JSON.stringify(value)
            : Array.isArray(value.challengesAndSolutions)
              ? JSON.stringify(value.challengesAndSolutions)
              : Array.isArray(value.items)
                ? JSON.stringify(value.items)
                : null
          : null
      },
      renderer: (value: any) => {
        const raw = ensureArrayOfObjectsFromJsonString(value)
        if (raw && Array.isArray(raw)) {
          return raw
        } else {
          const challenges = ensureObjectFromJsonString(value) || {}
          return (
            (challenges as any).challengesAndSolutions ||
            (challenges as any).items ||
            []
          )
        }
      },
      fieldName: 'challenges',
      description: 'The challenges and solutions for this opportunity',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.CONTEXT_ONLY,
    },
  },
  [NativeObjectTypes.MeetingRecording]: {
    title: {
      name: 'Title',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'title',
      description: 'The title of the meeting recording',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    description: {
      name: 'Description',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'description',
      description: 'The description of the meeting recording',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    people: {
      name: 'People',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'participants',
      description: 'The attendees in the meeting recording',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    domains: {
      name: 'Organizations',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'domains',
      description:
        'The organizations of the attendees in the meeting recording',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    domainString: {
      name: 'Organizations (search index)',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'domainString',
      description: 'The domains of the meeting recording FOR search index ONLY',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    participantString: {
      name: 'Participants (search index)',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'participantString',
      description:
        'The participant string of the meeting recording FOR search index ONLY',
      tablePolicy: StandardPropertyTablePolicy.HIDE,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    statusLabel: {
      name: 'Status',
      propertyTypeId: PropertyTypes.Picklist as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => (value ? value : null),
      fieldName: 'statusLabel',
      description: 'The status of the meeting recording',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    statusMessage: {
      name: 'Status (Message)',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => (value ? value : null),
      fieldName: 'statusMessage',
      description: 'Additional information about the reasoning for the status',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    statusReason: {
      name: 'Status (Reason)',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => (value ? value : null),
      fieldName: 'statusReason',
      description:
        'Reasoning information about the status of the meeting recording',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    userWasInMeeting: {
      name: 'Your Meeting',
      propertyTypeId: PropertyTypes.Boolean as PropertyTypeKey,
      caster: (value: boolean) => value?.toString() ?? null,
      renderer: (value: string) => (value ? value === 'true' : null),
      fieldName: 'userWasInMeeting',
      description: 'Whether the user was in the meeting',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
  },
  [NativeObjectTypes.Page]: {
    ownerEmail: {
      name: 'Owner Email',
      propertyTypeId: PropertyTypes.Email as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      fieldName: 'ownerEmail',
      description: 'The email of the page owner',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    title: {
      name: 'Title',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The title of the page',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    templateType: {
      name: 'Template Type',
      propertyTypeId: PropertyTypes.TextArea as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The template type of the page',
      tablePolicy: StandardPropertyTablePolicy.DEFAULT,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
    createdAt: {
      name: 'Created At',
      propertyTypeId: PropertyTypes.DateTime as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The date and time the page was created',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.DISABLED,
    },
    publishedForUserAt: {
      name: 'Shared with Workspace At',
      propertyTypeId: PropertyTypes.DateTime as PropertyTypeKey,
      caster: (value: string) => value,
      renderer: (value: string) => value,
      description: 'The date and time the page was published for the workspace',
      tablePolicy: StandardPropertyTablePolicy.SHOW,
      editPolicy: StandardPropertyEditPolicy.ALLOW,
    },
  },
}
