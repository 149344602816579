export const internalUserEmails = [
  'markitecht@gmail.com',
  'erik@ipsumcreative.com',
  'gwendolynr@gmail.com',
  'erin@day.ai',
  'michael.g.pici@gmail.com',
  'christopher@theproviders.com',
  'daphne@day.ai',
  'daphnegrayson@gmail.com',
  'stephen@day.ai',
  'gwen@day.ai',
  'will@day.ai',
  'todd@day.ai',
]

export const isInternalUser = (user) => {
  return internalUserEmails.includes(user.email)
}

export const gatedForWorkAccountRemediationBanner = ['michael.g.pici@gmail.com']

// SearchModalV2 & Meeting Recordings Table
export const ungatedForDayObjects = (_user) => {
  return true
}

// Orgs & People Tables
export const ungatedForDayObjectsPart2 = (user) => {
  return [
    'markitecht@gmail.com',
    'daphne@day.ai',
    'michael.g.pici@gmail.com',
    'erik@ipsumcreative.com',
  ].includes(user.email)
}

// New Sidebars
export const ungatedForDayObjectsPart3 = (user) => {
  return (
    process.env.HOST.includes('localhost') &&
    ['markitecht@gmail.com'].includes(user.email)
  )
}

// Sources & Reasoning
export const ungatedForDayObjectsPart4 = (user) => {
  return ['markitecht@gmail.com'].includes(user.email)
}

export const ungatedForNewMeetingsInSidebar = (user) => {
  return ['markitecht@gmail.com', 'alfred@listenlabs.ai'].includes(user.email)
}

export const ungatedForActivityReporting = (user) => {
  return ['markitecht@gmail.com'].includes(user.email)
}

export const ungatedForMarketing = (user) => {
  return ['markitecht@gmail.com', 'aj@parcha.ai', 'daphne@day.ai'].includes(
    user.email
  )
}

export const ungatedForCustomers = (user) => {
  return ['markitecht@gmail.com'].includes(user.email)
}

export const ungatedForProduct = (user) => {
  return ['markitecht@gmail.com', 'daphne@day.ai'].includes(user.email)
}

export const ungatedForViews = (user) => {
  if (!user) return true

  return [
    'markitecht@gmail.com',
    'daphne@day.ai',
    'gwendolynr@gmail.com',
    'will@day.ai',
  ].includes(user.email)
}

export const ungatedForInbox = (user) => {
  return internalUserEmails.includes(user.email)
}

export const coreContactAdmins = ['markitecht@gmail.com', 'erin@day.ai']

export const isCoreContactAdmin = (user) => {
  return coreContactAdmins.includes(user.email)
}

export const ungatedForTemplates = (user) => {
  return ['will@day.ai'].includes(user.email)
}

export const ungatedForKnowledge = (user) => {
  return [].includes(user.email)
}

export const ungatedForSlackNotifications = (_user) => {
  return true
}
