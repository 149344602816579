import Fuse from 'fuse.js'
import type { DayObject, DayObjectSearchEntry } from 'types/graphql'

import { logger as loggerDev } from 'src/lib/logger'
import {
  buildObjectReference,
  deepMerge,
  type NativeObjectType,
} from 'src/lib/objects'
import { searchMetadataBuilders } from 'src/lib/Objects/build'

const DB_NAME = 'day-objects-beta'
const DB_VERSION = 1
const STORES = {
  OBJECTS: 'objects',
  FUSE_INDEX: 'fuseIndex',
  SYNC_QUEUE: 'syncQueue',
} as const

const loggingEnabled = false

const logger = loggingEnabled
  ? loggerDev
  : {
      dev: () => {},
      info: () => {},
      warn: () => {},
      error: () => {},
    }

/*
  OBJECTS: one table that will store all objects, using a unique key that combines workspaceId, objectType, and objectId
  FUSE_INDEX: one table that will store the Fuse index for the objects, by workspaceId only (one index per workspace)
*/

export const initDb = async (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION)

    request.onerror = () => {
      logger.warn('Error opening IndexedDB', { error: request.error })
      reject(request.error)
    }

    request.onblocked = (event) => {
      logger.warn('IndexedDB blocked', { event })
    }

    request.onsuccess = () => {
      resolve(request.result)
    }

    request.onupgradeneeded = (event) => {
      logger.info('Upgrading IndexedDB')
      const db = (event.target as IDBOpenDBRequest).result

      // Create stores with compound indexes
      if (!db.objectStoreNames.contains(STORES.OBJECTS)) {
        const objectsStore = db.createObjectStore(STORES.OBJECTS, {
          keyPath: 'key',
        })
        objectsStore.createIndex('byWorkspace', 'workspaceId')
        objectsStore.createIndex('byWorkspaceAndType', [
          'workspaceId',
          'objectType',
        ])
        objectsStore.createIndex('lastUpdated', 'lastUpdated')
      }

      if (!db.objectStoreNames.contains(STORES.FUSE_INDEX)) {
        const fuseIndexStore = db.createObjectStore(STORES.FUSE_INDEX, {
          keyPath: 'workspaceId',
        })
        fuseIndexStore.createIndex('byWorkspace', 'workspaceId')
      }
      if (!db.objectStoreNames.contains(STORES.SYNC_QUEUE)) {
        const syncQueueStore = db.createObjectStore(STORES.SYNC_QUEUE, {
          keyPath: 'key',
        })
        syncQueueStore.createIndex('byWorkspaceAndObjectType', [
          'workspaceId',
          'objectType',
        ])
      }
    }
  })
}

const buildSyncQueueKey = ({
  objectType,
  objectId,
  workspaceId,
}: {
  objectType: NativeObjectType
  objectId: string
  workspaceId: string
}) => `${objectType}-${objectId}-${workspaceId}`

export const addToSyncQueue = async ({
  objectType,
  workspaceId,
  objectIds,
}: {
  objectType: NativeObjectType
  workspaceId: string
  objectIds: string[]
}) => {
  let db: IDBDatabase | null = null
  logger.dev('addToSyncQueue: Attempting to add IDs', {
    objectType,
    count: objectIds.length,
    sample: objectIds.slice(0, 5),
  }) // Log entry
  try {
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.SYNC_QUEUE, 'readwrite')
    const store = tx.objectStore(STORES.SYNC_QUEUE)

    const putPromises = objectIds.map(async (objectId) => {
      const key = buildSyncQueueKey({ objectType, objectId, workspaceId })

      return new Promise((resolve, reject) => {
        const request = store.get(key) // Check if exists first

        request.onsuccess = () => {
          if (!request.result) {
            // Doesn't exist, add it
            const syncEntry = {
              key,
              objectType,
              workspaceId,
              objectId,
              addedAt: new Date().toISOString(),
            }
            logger.dev('addToSyncQueue: Adding entry', { key }) // Log add attempt
            const addRequest = store.add(syncEntry)
            addRequest.onsuccess = () => {
              logger.dev('addToSyncQueue: Successfully added', { key }) // Log success
              resolve(undefined)
            }
            addRequest.onerror = () => {
              logger.warn('addToSyncQueue: Failed to add entry', {
                key,
                error: addRequest.error,
              }) // Log failure
              reject(addRequest.error)
            }
          } else {
            // Already exists, resolve quietly
            logger.dev('addToSyncQueue: Entry already exists, skipping', {
              key,
            })
            resolve(undefined)
          }
        }
        request.onerror = () => {
          logger.warn(
            'addToSyncQueue: Failed to get entry for existence check',
            { key, error: request.error }
          ) // Log failure
          reject(request.error)
        }
      })
    })

    await Promise.all(putPromises)
    logger.dev('addToSyncQueue: Finished processing batch', {
      objectType,
      count: objectIds.length,
    }) // Log completion
  } catch (error) {
    logger.warn('addToSyncQueue: Error during transaction', {
      objectType,
      error,
    }) // Log transaction error
  } finally {
    if (db) db.close()
  }
}

export const getSyncQueueEntries = async ({
  workspaceId,
  batchSize = 100,
}: {
  workspaceId: string
  batchSize?: number
}) => {
  let db: IDBDatabase | null = null
  let finalResults
  try {
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.SYNC_QUEUE, 'readonly')
    const store = tx.objectStore(STORES.SYNC_QUEUE)

    const results: { objectId: string; addedAt: string }[] = await new Promise(
      (resolve, reject) => {
        // Get all entries and filter in memory instead
        //const range = IDBKeyRange.only([workspaceId, objectType])
        const request = store.getAll()
        request.onsuccess = () => {
          const allEntries = request.result || []
          const filteredEntries = allEntries.filter((entry) => {
            return entry.workspaceId === workspaceId
          })
          resolve(filteredEntries)
        }
        request.onerror = () => reject(request.error)
      }
    )

    const sortedResults = results.sort((a, b) => {
      return new Date(a.addedAt).getTime() - new Date(b.addedAt).getTime()
    })

    finalResults = sortedResults.slice(0, batchSize)
  } catch (error) {
    logger.warn('Error getting sync queue entries', { error })
  } finally {
    if (db) db.close()
  }
  return finalResults
}

export const removeFromSyncQueue = async ({
  objectType,
  objectIds,
  workspaceId,
}: {
  objectType: NativeObjectType
  objectIds: string[]
  workspaceId: string
}) => {
  let db: IDBDatabase | null = null
  try {
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.SYNC_QUEUE, 'readwrite')
    const store = tx.objectStore(STORES.SYNC_QUEUE)

    for (const objectId of objectIds) {
      await store.delete(
        buildSyncQueueKey({ objectType, objectId, workspaceId })
      )
    }
  } finally {
    if (db) db.close()
  }
}

export const getDayObjectState = async ({
  workspaceId,
}: {
  workspaceId: string
}) => {
  let db: IDBDatabase | null = null
  let stateByObjectType: Record<NativeObjectType, any> | null = null

  try {
    await initDb()
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.OBJECTS, 'readonly')
    const store = tx.objectStore(STORES.OBJECTS)

    let results: DayObjectSearchEntry[] = []
    results = await new Promise<DayObjectSearchEntry[]>((resolve, reject) => {
      const request = store.index('byWorkspace').getAll(workspaceId)
      request.onsuccess = () => resolve(request.result)
      request.onerror = () => reject(request.error)
    })

    stateByObjectType = results.reduce(
      (acc, obj) => {
        const objectType = obj.objectType as NativeObjectType
        const currentState = acc[objectType] || {
          currentCount: 0,
          updatedAt: null,
          objectsNeedingSync: 0,
        }
        const updatedAt = new Date(obj.updatedAt)

        acc[objectType] = {
          currentCount: currentState.currentCount + 1,
          updatedAt:
            currentState.updatedAt === null ||
            new Date(updatedAt).getTime() >
              new Date(currentState.updatedAt).getTime()
              ? updatedAt
              : currentState.updatedAt,
          objectsNeedingSync:
            currentState.objectsNeedingSync + (obj.syncedAt ? 0 : 1),
        }

        return acc
      },
      {} as Record<
        NativeObjectType,
        {
          currentCount: number
          updatedAt: Date | null
          objectsNeedingSync: number
        }
      >
    )
  } catch (error) {
    logger.info('Error getting day object state', { error })
  } finally {
    if (db) db.close()
  }
  return stateByObjectType
}

export const getDayObjectStateByType = async ({
  workspaceId,
  objectType,
}: {
  workspaceId: string
  objectType: NativeObjectType
}) => {
  let db: IDBDatabase | null = null
  let stateByObjectType: Record<
    NativeObjectType,
    {
      currentCount: number
      updatedAt: Date | null
      objectsNeedingSync: number
    }
  > | null = null
  try {
    await initDb()
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.OBJECTS, 'readonly')
    const store = tx.objectStore(STORES.OBJECTS)

    const results: DayObjectSearchEntry[] = await new Promise(
      (resolve, reject) => {
        const range = IDBKeyRange.only([workspaceId, objectType])
        const request = store.index('byWorkspaceAndType').getAll(range)
        request.onsuccess = () => resolve(request.result)
        request.onerror = () => reject(request.error)
      }
    )

    stateByObjectType = results.reduce(
      (acc, obj) => {
        const objectType = obj.objectType as NativeObjectType
        const currentState = acc[objectType] || {
          currentCount: 0,
          updatedAt: null,
          objectsNeedingSync: 0,
        }
        const updatedAt = new Date(obj.updatedAt)

        acc[objectType] = {
          currentCount: currentState.currentCount + 1,
          updatedAt:
            currentState.updatedAt === null ||
            updatedAt > currentState.updatedAt
              ? updatedAt
              : currentState.updatedAt,
          objectsNeedingSync:
            currentState.objectsNeedingSync + (obj.syncedAt ? 0 : 1),
        }

        return acc
      },
      {} as Record<
        NativeObjectType,
        {
          currentCount: number
          updatedAt: Date | null
          objectsNeedingSync: number
        }
      >
    )
  } catch (error) {
    logger.warn('Error getting day object state by type', { error })
  } finally {
    if (db) db.close()
  }
  return stateByObjectType
}

export const getNeverSyncedObjects = async ({
  workspaceId,
}: {
  workspaceId: string
}) => {
  let db: IDBDatabase | null = null
  let neverSyncedObjects: DayObjectSearchEntry[] | null = null
  try {
    await initDb()
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.OBJECTS, 'readonly')
    const store = tx.objectStore(STORES.OBJECTS)

    const results: DayObjectSearchEntry[] = await new Promise(
      (resolve, reject) => {
        const request = store.index('byWorkspace').getAll(workspaceId)
        request.onsuccess = () => resolve(request.result)
        request.onerror = () => reject(request.error)
      }
    )

    neverSyncedObjects = results.filter(
      (obj) => !obj.syncedAt || !obj?.object?.updatedAt
    )
  } catch (error) {
    logger.warn('Error getting never synced objects', { error })
  } finally {
    if (db) db.close()
  }
  return neverSyncedObjects
}

export const upsertDayObjects = async ({
  objects,
  objectType,
  workspaceId,
  synced = false,
}: {
  objects: Partial<DayObject>[]
  objectType: NativeObjectType
  workspaceId: string
  synced?: boolean
}) => {
  if (!objects || !Array.isArray(objects)) {
    logger.warn('Invalid objects in upsertDayObjects', { objects })
    return
  }

  let db: IDBDatabase | null = null

  try {
    await initDb()
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.OBJECTS, 'readwrite')
    const store = tx.objectStore(STORES.OBJECTS)

    // Get all existing objects for this workspace
    const existingObjects = await new Promise<any[]>((resolve, reject) => {
      const request = store.index('byWorkspace').getAll(workspaceId)
      request.onsuccess = () => resolve(request.result)
      request.onerror = () => reject(request.error)
    })

    // Create a map of existing objects by their key for faster lookup
    const existingObjectsMap = existingObjects
      ? new Map(existingObjects.map((obj) => [obj.key, obj]))
      : new Map()

    // Process each object individually
    const putPromises = objects.map(async (obj) => {
      if (!obj || !obj.objectId) {
        logger.warn('Invalid object in upsertDayObjects', { obj })
        return Promise.resolve()
      }

      // Create a unique key for the object
      const key = buildObjectReference({
        workspaceId,
        objectId: obj.objectId,
        objectType,
      })

      const searchMetadata =
        synced && obj.properties ? searchMetadataBuilders[objectType](obj) : {}
      if (!searchMetadata) {
        logger.warn('Could not build search metadata for object', {
          objectId: obj.objectId,
          objectType,
        })
        return Promise.resolve()
      }

      // Ensure we have a valid updatedAt timestamp
      // If obj.updatedAt is missing or invalid, use current time
      const currentTime = new Date().toISOString()
      const updatedAt = obj.updatedAt ? obj.updatedAt : currentTime

      const newObject = {
        key,
        workspaceId,
        objectType,
        objectId: obj.objectId,
        updatedAt, // Use the validated updatedAt
        syncedAt: synced ? currentTime : null,
        object: obj,
        ...searchMetadata,
      }

      // Check if the object exists by its key
      const existingObject = existingObjectsMap.get(key)

      if (existingObject) {
        // If it exists, merge with the existing object
        const mergedObject = deepMerge(existingObject, newObject)

        // Ensure we keep the most recent updatedAt timestamp
        // Use fallbacks to handle potentially missing or invalid dates
        const existingUpdatedAt = existingObject.updatedAt
          ? new Date(existingObject.updatedAt).getTime()
          : 0
        const newUpdatedAt = updatedAt
          ? new Date(updatedAt).getTime()
          : new Date().getTime()

        const mostRecentUpdatedAt = Math.max(existingUpdatedAt, newUpdatedAt)
        mergedObject.updatedAt = new Date(mostRecentUpdatedAt).toISOString()

        return new Promise((resolve, reject) => {
          const request = store.put(mergedObject)
          request.onsuccess = () => resolve(undefined)
          request.onerror = () => {
            // Log error separately from the error handling
            logger.warn('Error updating object in IndexedDB', {
              objectId: obj.objectId,
            })
            reject(request.error)
          }
        })
      } else {
        // If it doesn't exist, add it as a new object
        // Ensure the new object has a valid updatedAt
        return new Promise((resolve, reject) => {
          const request = store.add(newObject)
          request.onsuccess = () => resolve(undefined)
          request.onerror = () => {
            // Log error separately from the error handling
            logger.warn('Error adding new object to IndexedDB', {
              objectId: obj.objectId,
            })
            reject(request.error)
          }
        })
      }
    })

    // Wait for all operations to complete
    await Promise.all(putPromises.filter(Boolean))
    if (synced) {
      await removeFromSyncQueue({
        objectType,
        objectIds: objects.map((obj) => obj.objectId),
        workspaceId,
      })
    }
  } finally {
    if (db) db.close()
  }
}

export const getDayObjectSearchEntry = async ({
  workspaceId,
  objectType,
  objectId,
}: {
  workspaceId: string
  objectType: NativeObjectType
  objectId: string
}): Promise<DayObjectSearchEntry | null> => {
  let db: IDBDatabase | null = null
  let result: DayObjectSearchEntry | null = null
  try {
    await initDb()
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.OBJECTS, 'readonly')
    const store = tx.objectStore(STORES.OBJECTS)

    const key = buildObjectReference({
      workspaceId,
      objectId,
      objectType,
    })
    logger.dev('Getting object', { key })
    result = await new Promise<any>((resolve, reject) => {
      const request = store.get(key)
      request.onsuccess = () => resolve(request.result)
      request.onerror = () => reject(request.error)
    })
    logger.dev('Got object', { result })
  } catch (error) {
    logger.warn('Error getting day object search entry', { error })
  } finally {
    if (db) db.close()
  }
  return result
}

const filteredResults = ({
  result,
  objectType,
  objectIds,
}: {
  result: DayObjectSearchEntry[]
  objectType: NativeObjectType
  objectIds: string[]
}): DayObjectSearchEntry[] => {
  return (result || []).filter((searchEntry) => {
    if (
      objectIds.includes(searchEntry.objectId) &&
      searchEntry.object?.objectType === objectType &&
      searchEntry.object?.objectId
    ) {
      return true
    } else {
      return false
    }
  })
}

export const getDayObjects = async ({
  workspaceId,
  objectType,
  objectIds,
}: {
  workspaceId: string
  objectType: NativeObjectType
  objectIds: string[]
}): Promise<DayObjectSearchEntry[]> => {
  let db: IDBDatabase | null = null
  let result: DayObjectSearchEntry[] | null = null
  try {
    if (!objectIds || objectIds.length === 0) {
      logger.warn('No object ids provided to getDayObjects', {
        objectType,
        workspaceId,
      })
      return []
    }
    await initDb()
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.OBJECTS, 'readonly')
    const store = tx.objectStore(STORES.OBJECTS)

    result = await new Promise<any>((resolve, reject) => {
      const range = IDBKeyRange.only([workspaceId, objectType])
      const request = store.index('byWorkspaceAndType').getAll(range)
      request.onsuccess = () => resolve(request.result)
      request.onerror = () => reject(request.error)
    })
    logger.dev('getDayObjects result', { result })
  } catch (error) {
    logger.warn('Error getting day objects', { error })
  } finally {
    if (db) db.close()
  }

  return filteredResults({ result, objectType, objectIds })
}

export const getDayObjectsByType = async ({
  workspaceId,
  objectType,
}: {
  workspaceId: string
  objectType: NativeObjectType
}): Promise<DayObjectSearchEntry[]> => {
  let db: IDBDatabase | null = null
  let result: DayObjectSearchEntry[] | null = null
  try {
    await initDb()
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.OBJECTS, 'readonly')
    const store = tx.objectStore(STORES.OBJECTS)

    result = await new Promise<DayObjectSearchEntry[]>((resolve, reject) => {
      const range = IDBKeyRange.only([workspaceId, objectType])
      const request = store.index('byWorkspaceAndType').getAll(range)
      request.onsuccess = () => resolve(request.result || [])
      request.onerror = () => reject(request.error)
    })
  } catch (error) {
    logger.warn('Error getting day objects by type', { error })
  } finally {
    if (db) db.close()
  }
  return result
}

export const getDayObjectsRecentByTypes = async ({
  workspaceId,
  objectTypes,
}: {
  workspaceId: string
  objectTypes: NativeObjectType[]
}): Promise<DayObjectSearchEntry[]> => {
  let db: IDBDatabase | null = null
  let result: Record<NativeObjectType, DayObjectSearchEntry[]> | null = null
  try {
    await initDb()
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.OBJECTS, 'readonly')
    const store = tx.objectStore(STORES.OBJECTS)

    let results: DayObjectSearchEntry[] = []
    results = await new Promise<DayObjectSearchEntry[]>((resolve, reject) => {
      const request = store.index('byWorkspace').getAll(workspaceId)
      request.onsuccess = () => resolve(request.result)
      request.onerror = () => reject(request.error)
    })
    const output = {}
    const resultsByType = {}
    for (const objectType of objectTypes) {
      resultsByType[objectType] =
        (results.filter(
          (obj) => obj.objectType === objectType
        ) as DayObjectSearchEntry[]) || []
    }
    for (const objectType of objectTypes) {
      const sortedResultsByType = resultsByType[objectType].sort((a, b) => {
        const dateA = new Date(a?.object?.updatedAt)
        const dateB = new Date(b?.object?.updatedAt)
        return dateB.getTime() - dateA.getTime()
      })
      output[objectType] =
        (sortedResultsByType.slice(0, 10) as DayObjectSearchEntry[]) || []
    }

    result = output
  } catch (error) {
    logger.warn('Error getting day objects by type', { error })
  } finally {
    if (db) db.close()
  }
  return result
}

export const deleteDayObjectSearchEntry = async (key: string) => {
  let db: IDBDatabase | null = null
  let result: undefined | null = null
  try {
    await initDb()
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.OBJECTS, 'readwrite')
    const store = tx.objectStore(STORES.OBJECTS)

    result = await new Promise<undefined | null>((resolve, reject) => {
      const request = store.delete(key)
      request.onsuccess = () => resolve(undefined)
      request.onerror = () => reject(request.error)
    })
  } catch (error) {
    logger.warn('Error deleting day object search entry', { error })
  } finally {
    if (db) db.close()
  }
  return result
}

// First, let's adjust the search options to properly weight the fields
export const searchOptions = {
  keys: [
    { name: 'label', weight: 2 }, // Highest priority
    { name: 'searchText', weight: 1.5 }, // Medium-high priority
    //{ name: 'description', weight: 0.1 }, // Normal priority
  ],
  includeScore: true,
  threshold: 0.2,
  distance: 100,
  ignoreLocation: true,
  findAllMatches: true,
  useExtendedSearch: true,
  minMatchCharLength: 4,
}

export const buildFuseIndex = async (workspaceId: string): Promise<void> => {
  let db: IDBDatabase | null = null

  try {
    await initDb()
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(
      [STORES.OBJECTS, STORES.FUSE_INDEX],
      'readwrite'
    )

    const objectStore = tx.objectStore(STORES.OBJECTS)
    const fuseIndexStore = tx.objectStore(STORES.FUSE_INDEX)
    const objects: DayObjectSearchEntry[] = await new Promise(
      (resolve, reject) => {
        const request = objectStore.index('byWorkspace').getAll(workspaceId)
        request.onerror = () => reject(request.error)
        request.onsuccess = () => resolve(request.result)
      }
    )

    if (!objects || objects.length === 0) {
      logger.warn('No objects found to build index for workspace', {
        workspaceId,
      })
      return
    }

    // Transform objects into searchable format with searchText
    const searchableObjects = objects.map((obj) => {
      // Get the base searchable object
      const searchable = {
        label:
          obj.label || obj.object?.name || obj.object?.title || obj.objectId,
        description: obj.description || obj.object?.description,
        objectType: obj.objectType,
        objectId: obj.objectId,
        workspaceId: obj.workspaceId,
        properties: obj.object || {},
        updatedAt: obj.updatedAt,
        // Ensure searchText is included from the original object
        searchText: obj.searchText || '',
      }

      return searchable
    })

    // Create Fuse index with all fields
    const fuseIndex = Fuse.createIndex(
      searchOptions.keys.map((k) => (typeof k === 'string' ? k : k.name)),
      searchableObjects
    )

    const indexData = {
      workspaceId,
      index: fuseIndex.toJSON(),
      lastBuilt: new Date().toISOString(),
      objectCount: objects.length,
    }

    await new Promise<void>((resolve, reject) => {
      const request = fuseIndexStore.put(indexData)
      request.onerror = () => {
        logger.error('Error saving Fuse index:', request.error)
        reject(request.error)
      }
      request.onsuccess = () => {
        resolve()
      }
    })
  } catch (error) {
    logger.error('Failed to build Fuse index:', error)
    throw error
  } finally {
    if (db) db.close()
  }
}

// Add a function to retrieve the Fuse index (will be used by SearchModal later)
export const getFuseIndex = async (workspaceId: string) => {
  let db: IDBDatabase | null = null
  let result: any | null = null
  try {
    await initDb()
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.FUSE_INDEX, 'readonly')
    const store = tx.objectStore(STORES.FUSE_INDEX)

    result = await new Promise((resolve, reject) => {
      const request = store.get(workspaceId)
      request.onerror = () => reject(request.error)
      request.onsuccess = () => resolve(request.result)
    })
  } catch (error) {
    logger.warn('Error getting Fuse index', { error })
  } finally {
    if (db) db.close()
  }
  return result
}

// In the searchWithFuseIndex function, ensure we're using the same transformation
export const searchWithFuseIndex = async ({
  workspaceId,
  query,
  objectType,
}: {
  workspaceId: string
  query?: string
  objectType?: NativeObjectType
}): Promise<DayObjectSearchEntry[]> => {
  let db: IDBDatabase | null = null
  let filteredResults: DayObjectSearchEntry[] | null = null

  try {
    await initDb()
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.OBJECTS, 'readonly')
    if (!query?.trim()) {
      return getDayObjectsByType({ workspaceId, objectType })
    }

    const [objects, indexData] = await Promise.all([
      // Get objects
      new Promise<DayObjectSearchEntry[]>((resolve, reject) => {
        const store = tx.objectStore(STORES.OBJECTS)
        const request = store.index('byWorkspace').getAll(workspaceId)
        request.onerror = () => reject(request.error)
        request.onsuccess = () => resolve(request.result)
      }),
      // Get index
      getFuseIndex(workspaceId),
    ])

    if (!indexData || !objects?.length) {
      logger.warn('No Fuse index or objects found for workspace', {
        workspaceId,
      })
      return []
    }

    // Transform objects using the same format as in buildFuseIndex
    const searchableObjects = objects

    const fuse = new Fuse(
      searchableObjects,
      searchOptions,
      Fuse.parseIndex(indexData.index)
    )

    const results = fuse.search(query)

    filteredResults = results
      .map((result) => result.item as DayObjectSearchEntry)
      .filter((item) => !objectType || item.objectType === objectType)
  } catch (error) {
    logger.error('Search failed:', error)
    return []
  } finally {
    if (db) db.close()
  }
  return filteredResults
}

export async function clearDayObjects(): Promise<void> {
  try {
    // Delete the entire database
    await new Promise<void>((resolve, reject) => {
      const request = indexedDB.deleteDatabase(DB_NAME)

      request.onerror = () => {
        logger.error('Failed to delete database:', request.error)
        reject(request.error)
      }

      request.onsuccess = () => {
        logger.dev('Successfully deleted database')
        resolve()
      }
    })
  } catch (error) {
    logger.error('Failed to clear day objects:', error)
    throw error
  }
}

// --- New Purge Function ---
export const purgeOldSyncQueueEntries = async ({
  workspaceId,
  purgeBeforeDate,
}: {
  workspaceId: string
  purgeBeforeDate: Date
}) => {
  let db: IDBDatabase | null = null
  let deletedCount = 0
  logger.dev('purgeOldSyncQueueEntries: Starting purge', {
    purgeBeforeDate: purgeBeforeDate.toISOString(),
  })

  try {
    db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.SYNC_QUEUE, 'readwrite')
    const store = tx.objectStore(STORES.SYNC_QUEUE)

    // We need to iterate as 'addedAt' is not indexed
    await new Promise<void>((resolve, reject) => {
      const cursorRequest = store.openCursor()
      cursorRequest.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result
        if (cursor) {
          const entry = cursor.value
          // Check if the entry belongs to the target workspace and is older than the purge date
          if (
            entry.workspaceId === workspaceId &&
            new Date(entry.addedAt) < purgeBeforeDate
          ) {
            logger.dev('purgeOldSyncQueueEntries: Deleting old entry', {
              key: entry.key,
              addedAt: entry.addedAt,
            })
            const deleteRequest = cursor.delete()
            deleteRequest.onsuccess = () => {
              deletedCount++
            }
            deleteRequest.onerror = () => {
              logger.warn('purgeOldSyncQueueEntries: Failed to delete entry', {
                key: entry.key,
                error: deleteRequest.error,
              })
            }
          }
          cursor.continue()
        } else {
          resolve() // Cursor finished
        }
      }
      cursorRequest.onerror = (event) => {
        logger.warn('purgeOldSyncQueueEntries: Error opening cursor', {
          error: (event.target as IDBRequest).error,
        })
        reject((event.target as IDBRequest).error)
      }
    })

    await new Promise<void>((resolve, reject) => {
      tx.oncomplete = () => resolve()
      tx.onerror = () => reject(tx.error)
      tx.onabort = () => reject(tx.error)
    })

    logger.dev('purgeOldSyncQueueEntries: Purge complete', { deletedCount })
  } catch (error) {
    logger.warn('purgeOldSyncQueueEntries: Error during transaction', { error })
  } finally {
    if (db) db.close()
  }
  return deletedCount
}
// --- End New Purge Function ---
