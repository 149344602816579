import { lighten } from '@mui/material'
import {
  IconCheck,
  IconEqual,
  IconMathGreater,
  IconMathLower,
  IconMathEqualGreater,
  IconMathEqualLower,
  IconX,
  IconDots,
} from '@tabler/icons-react'

export const viewsColors = {
  columns: '#8A6FD1',
  filters: '#37B393',
  groupings: '#DE7C45',
  sorts: '#4B97D2',
}

const modifierBadge = {
  borderLighten: 0.1,
  backgroundLighten: 0.4,
  textDarken: 0.1,
}

const standardButtonWidth = '172px'

export const viewManagerContainerSx = {
  height: '32px',
  alignItems: 'flex-start',
  '& .view-manager-tabs-container': {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    zIndex: 10,
    overflow: 'visible',
    position: 'relative',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .view-tab': {
      width: standardButtonWidth,
      '& .MuiTypography-root': {
        fontWeight: 600,
        textOverflow: 'ellipsis',
        width: '96px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        letterSpacing: '-0.3px',
      },

      '&.Mui-selected': {
        '& .MuiTypography-root': {
          fontWeight: 600,
        },
      },
    },
  },

  '& .display-settings-button': {
    borderRadius: '12px',
    '& .modifier-badge-row': {
      '& .modifier-badge': {
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        mr: '-8px',

        '&.sorts': {
          backgroundColor: lighten(
            viewsColors.sorts,
            modifierBadge.backgroundLighten
          ),
          border: `1px solid ${lighten(viewsColors.sorts, modifierBadge.borderLighten)}`,
          color: lighten(viewsColors.sorts, modifierBadge.textDarken),
        },

        '&.filters': {
          backgroundColor: lighten(
            viewsColors.filters,
            modifierBadge.backgroundLighten
          ),
          border: `1px solid ${lighten(viewsColors.filters, modifierBadge.borderLighten)}`,
          color: lighten(viewsColors.filters, modifierBadge.textDarken),
        },

        '&.groupings': {
          backgroundColor: lighten(
            viewsColors.groupings,
            modifierBadge.backgroundLighten
          ),
          border: `1px solid ${lighten(viewsColors.groupings, modifierBadge.borderLighten)}`,
          color: lighten(viewsColors.groupings, modifierBadge.textDarken),
        },

        '&.columns': {
          backgroundColor: lighten(
            viewsColors.columns,
            modifierBadge.backgroundLighten
          ),
          border: `1px solid ${lighten(viewsColors.columns, modifierBadge.borderLighten)}`,
          color: lighten(viewsColors.columns, modifierBadge.textDarken),
        },
      },

      '& .modifiers-count': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '16px',
        width: '16px',
        borderRadius: '50%',
        backgroundColor: (theme) => theme.palette.primary.main,
        '& .MuiTypography-root': {
          fontSize: '10px',
          fontWeight: 600,
          color: (theme) => theme.palette.primary.contrastText,
        },
      },
    },
  },
}

const textShadowParams = '0px 0.3px 0.3px'
const textShadowDarken = 0.5
export const displaySettingsSx = {
  height: '36px',
  justifyContent: 'space-between',

  '& .chip-row': {
    gap: '4px',

    '& .MuiChip-root': {
      height: '24px',
      width: standardButtonWidth,
      justifyContent: 'space-between',
      '& .MuiChip-icon': {
        mr: '0px',
        ml: '6px',
        color: 'inherit',
        height: '11px',
        width: '11px',
        flexShrink: 0,
      },

      '& .MuiChip-deleteIcon': {
        color: 'inherit',
        height: '12px',
        width: '12px',
        mr: '12px',
      },

      '& .MuiChip-label': {
        fontSize: '11px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '-0.2px',
      },

      '&.columns': {
        backgroundColor: lighten(viewsColors.columns, 0.9),
        borderColor: lighten(viewsColors.columns, 0.7),
        color: viewsColors.columns,
        textShadow: `${textShadowParams} ${lighten(viewsColors.columns, textShadowDarken)}`,
      },

      '&.filters': {
        backgroundColor: lighten(viewsColors.filters, 0.9),
        borderColor: lighten(viewsColors.filters, 0.7),
        color: viewsColors.filters,
        textShadow: `${textShadowParams} ${lighten(viewsColors.filters, textShadowDarken)}`,
      },

      '&.groupings': {
        backgroundColor: lighten(viewsColors.groupings, 0.9),
        borderColor: lighten(viewsColors.groupings, 0.7),
        color: viewsColors.groupings,
        textShadow: `${textShadowParams} ${lighten(viewsColors.groupings, textShadowDarken)}`,
      },

      '&.sorts': {
        backgroundColor: lighten(viewsColors.sorts, 0.9),
        borderColor: lighten(viewsColors.sorts, 0.7),
        color: viewsColors.sorts,
        textShadow: `${textShadowParams} ${lighten(viewsColors.sorts, textShadowDarken)}`,
      },
    },
  },
}

export const tabsContainerSx = {}

export const refreshButtonSx = {
  p: '4px',
  borderRadius: '3px',
}

export const OperatorMetadata = {
  equality: {
    label: 'Equal to',
    icon: IconEqual,
  },
  inequality: {
    label: 'Is not',
    icon: IconEqual,
  },
  presence: {
    label: 'Is not',
    icon: IconEqual,
  },
  lackOfPresence: {
    label: 'Is not',
    icon: IconCheck,
  },
  greaterThan: {
    label: 'Is greater than',
    icon: IconMathGreater,
  },
  lessThan: {
    label: 'Is less than',
    icon: IconMathLower,
  },
  greaterThanOrEqual: {
    label: 'Is greater than or equal to',
    icon: IconMathEqualGreater,
  },
  lessThanOrEqual: {
    label: 'Is less than or equal to',
    icon: IconMathEqualLower,
  },
}

export const OperatorMetadataByValue = {
  equals: {
    label: 'Equal to',
    icon: IconEqual,
  },
  is: {
    label: 'Is',
    icon: IconEqual,
  },
  '=': {
    label: 'Equal to',
    icon: IconEqual,
  },
  contains: {
    label: 'Contains',
    icon: IconEqual,
  },
  doesNotContain: {
    label: 'Does not contain',
    icon: IconX,
  },
  doesNotEqual: {
    label: 'Does not equal',
    icon: IconX,
  },
  startsWith: {
    label: 'Starts with',
    icon: IconDots,
  },
  endsWith: {
    label: 'Ends with',
    icon: IconDots,
  },
  '!=': {
    label: 'Is not',
    icon: IconEqual,
  },
  '>': {
    label: 'Is greater than',
    icon: IconMathGreater,
  },
  '<': {
    label: 'Is less than',
    icon: IconMathLower,
  },
  '>=': {
    label: 'Is greater than or equal to',
    icon: IconMathEqualGreater,
  },
  '<=': {
    label: 'Is less than or equal to',
    icon: IconMathEqualLower,
  },
  isEmpty: {
    label: 'Is empty',
    icon: IconEqual,
  },
  isNotEmpty: {
    label: 'Is not empty',
    icon: IconEqual,
  },
  isAnyOf: {
    label: 'Is any of',
    icon: IconEqual,
  },
  isNotAnyOf: {
    label: 'Is not any of',
    icon: IconEqual,
  },
}
